
import {mapGetters} from 'vuex';
import {rzContainer, rzButton, rzDropdown, rzDottedLoader, rzIconDropArrow, rzTransitionExpand} from 'razlet-ui';
import MainLogo from '~/components/main-logo';
import MainContacts from '~/components/main-contacts';
import timeSrc from '~/assets/img/time.png';
import weatherSrc from '~/assets/img/weather.png';
import eyeSrc from '~/assets/img/eye-icon.webp';
import {lockScroll, unlockScroll} from '~/utils/lock-scroll';

export default {
  name: 'main-header',
  components: {
    rzContainer,
    rzDropdown,
    rzButton,
    rzDottedLoader,
    MainLogo,
    rzIconDropArrow,
    rzTransitionExpand,
    MainContacts,
  },
  data() {
    return {
      isMenu: false,
      activeMenuIndex: null,
      timer: null,
      currentDate: null,
      timeSrc,
      weatherSrc,
      eyeSrc,
    };
  },
  computed: {
    ...mapGetters('sulu/airport', ['weather']),
    ...mapGetters('sulu', ['menu']),
  },
  watch: {
    '$route': {
      handler() {
        this.isMenu = false;
        setTimeout(() => {
          unlockScroll();
        }, 320);
      },
    },
    deep: true,
  },
  created() {
    this.getDate();
    this.timer = setInterval(() => {
      this.getDate();
    }, 61000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    setActiveMenuIndexByRoute() {
      this.menu.forEach((head, index) => {
        head.items.forEach(subItem => {
          if (subItem.link === this.$route.path) {
            this.activeMenuIndex = index;
          }
        });
      });
    },
    changeLocale(locale) {
      this.$local = locale;
    },
    getDate() {
      const date = new Date();
      const dateStr = date.toLocaleString('ru-RU', { 
        timeZone: 'Europe/Moscow',
        hour: '2-digit',
        minute: '2-digit',
        month: 'long',
        day: 'numeric',
      });

      this.currentDate = `${dateStr.replace(',', '')} msk`;
    },
    openMenu() {
      if (!this.isMenu) {
        this.setActiveMenuIndexByRoute();
        lockScroll();
      }
      else setTimeout(() => {
        unlockScroll();
      }, 320);
      this.isMenu = !this.isMenu;
    },
    goTo(route) {
      if (this.$route.name !== route) {
        this.$router.push({ name: route });
        this.isMenu = false;
      }
    },
    toggleMenuItem(index) {
      this.activeMenuIndex = this.activeMenuIndex === index ? -1 : index;
    },
    isMenuItemActive(index) {
      if (this.activeMenuIndex === null) return false;
      return this.activeMenuIndex === index;
    },
  },
};
